import appApi from "./appApi";
import {
  InstanceDataType,
  InstanceType,
  AddationalInstanceTypes,
} from "./api.types";

// Create a link element
const link = document.createElement("a");
const instanceApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    createInstance: build.mutation<InstanceDataType, InstanceType>({
      query: (instanceData) => ({
        url: `/customer-instance/`,
        method: "POST",
        body: instanceData,
      }),
      invalidatesTags: ["Instance"],
    }),
    getInstance: build.query<
      InstanceDataType,
      { debounceValue: string; rowsPerPage: number | null }
    >({
      query: ({ debounceValue, rowsPerPage }) =>
        `/customer-instance/?search=${debounceValue}&page_size=${rowsPerPage}`,
      providesTags: ["Instance"],
    }),
    getInstanceById: build.query<
      InstanceType & AddationalInstanceTypes,
      string
    >({
      query: (instanceId) => `/customer-instance/${instanceId}`,
      providesTags: (result, error, id) => [{ type: "Instance", id }],
      keepUnusedDataFor: 5,
    }),
    updateRadeaInstance: build.mutation<InstanceDataType, string>({
      query: (instanceId) => ({
        url: `/customer-instance/${instanceId}/instance-status`,
        method: "GET",
      }),
      invalidatesTags: ["Instance"],
    }),
    editInstance: build.mutation<
      InstanceDataType,
      { id: string; patch: InstanceType }
    >({
      query: (instanceData) => ({
        url: `/customer-instance/${instanceData.id}/`,
        method: "PATCH",
        body: instanceData.patch,
      }),
      invalidatesTags: ["Instance"],
    }),
    deleteInstance: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer-instance/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Instance"],
    }),
    launchInstance: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer-instance/${id}/launch/`,
        method: "POST",
      }),
      invalidatesTags: ["Instance"],
    }),
    pauseInstance: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer-instance/${id}/pause/`,
        method: "POST",
      }),
      invalidatesTags: ["Instance"],
    }),
    getInstancePaginated: build.query<InstanceDataType, any>({
      query: ({ page, rowsPerPage }) =>
        `/customer-instance/?page=${page}&page_size=${rowsPerPage}`,
      providesTags: ["Instance"],
    }),
    exportInstances: build.mutation<null, any>({
      query: () => ({
        url: `/customer-instance/export/`,
        method: 'GET',
        responseHandler: async (response) => {
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData?.detail || 'Export failed');
          }
          // Create a blob from the response
          const blob = await response.blob();

          // Create a URL for the blob and set it as the href attribute
          const url = window.URL.createObjectURL(blob);
          link.href = url;

          // Set the download attribute with the desired file name
          link.download = "instances.csv";

          // Append the link to the document
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the document
          link.remove();

          // Revoke the object URL after the download is triggered
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
    exportReaderInformation: build.mutation<null, any>({
      query: () => ({
        url: `/reader-gateway/reader-serial/export/`,
        method: 'GET',
        responseHandler: async (response) => {
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData?.detail || 'Export failed');
          }
          // Create a blob from the response
          const blob = await response.blob();

          // Create a URL for the blob and set it as the href attribute
          const url = window.URL.createObjectURL(blob);
          link.href = url;

          // Set the download attribute with the desired file name
          link.download = "reader_information.csv";

          // Append the link to the document
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the document
          link.remove();

          // Revoke the object URL after the download is triggered
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useCreateInstanceMutation,
  useGetInstanceQuery,
  useGetInstanceByIdQuery,
  useEditInstanceMutation,
  useDeleteInstanceMutation,
  useLaunchInstanceMutation,
  usePauseInstanceMutation,
  useGetInstancePaginatedQuery,
  useUpdateRadeaInstanceMutation,
  useExportInstancesMutation,
  useExportReaderInformationMutation,
} = instanceApi;
